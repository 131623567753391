import React, { Component } from 'react';
import Constants from './Constants';

class SignupRequest extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    var bid = localStorage.getItem(Constants.hhBusinessIdKey);
    let cb = localStorage.getItem(Constants.hhAuthCallbackKey);
    let redirectUrl = `${cb}/app/signup?bid=${bid}`;
    window.location.href = redirectUrl;

    return ( <div>{Constants.successMessage}</div> );
  }
};

export default SignupRequest;