var Constants = {
    hhAuthCallbackKey: 'hh-auth-callback-key',
    hhBusinessIdKey: 'hh-business-id-key',
    allowedCallbackUrls: [
        "http://localhost:8080", // when ui is merged with service
        "http://localhost:9090", // ui is run seperately
	"https://us-portal-dev.haulerhero.com", //cloudrun test
        "https://us-portal-dev1.haulerhero.com",
	"https://us-portal-dev2.haulerhero.com",
	      "https://us-portal-staging.haulerhero.com",
	      "https://us-portal-rc.haulerhero.com",        
	      "https://portal-preview.haulerhero.com",
        "https://portal.haulerhero.com"
      ],
    errorMessage: "Invalid application URL!",
    successMessage: "Please wait...",
    loggingOutMessage: "You have successfully logged out. You may close this window now."
};

export default Constants;
