import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import config from './config';
import Home from './Home';
import SignupRequest from './SignupRequest';
import ForgotPasswordRequest from './ForgotPasswordRequest';
import Constants from './Constants';

const oktaAuth = new OktaAuth(config.oidc);
const history = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.restoreOriginalUri = this.restoreOriginalUri.bind(this);
  }

  restoreOriginalUri(oktaAuth, originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }

  render() {
    return (
      <Router history={history}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
          <Route path="/"       exact render={(props) => <Home {...props} mode={"login"} />}  />
          <Route path="/next"   exact render={(props) => <Home {...props} mode={"next"} />}   />
          <Route path="/logout" exact render={(props) => <Home {...props} mode={"logout"} />} />
          <Route path="/login/callback" component={(props) => {
              let url = props?.location?.search;
              let params = queryString.parse(url);
              // When access is denied then OKTA doesn't returns the callback URL, so using this
              const callerURL = window.localStorage.getItem(Constants.hhAuthCallbackKey);
              if (params?.error === "access_denied" && callerURL) {
                return (<Redirect to={{pathname: "/logout", search: "?postLogoutRedirectUrl=" + callerURL}} />);
              }

              return <LoginCallback />;
            }}
          />
          <Route path="/signup-request" exact render={(props) => <SignupRequest {...props} />} />
          <Route path="/forgot-password-request" exact render={(props) => <ForgotPasswordRequest {...props} />} />
        </Security>
      </Router>
    );
  }
}

export default App;
