import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import Constants from './Constants';
import queryString from 'query-string';

export default withOktaAuth(class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: null, userInfo: null };
    this.login = this.login.bind(this);
    this.alreadyLoggedIn = this.alreadyLoggedIn.bind(this);
    this.logout = this.logout.bind(this);
    this.handlePath = this.handlePath.bind(this);

    this.handlePathInProgess = false;
  }

  async componentDidMount() {
    if(this.props.mode === "logout") return;

    this.checkAuthentication();
  }

  async componentDidUpdate() {
    if(this.props.mode === "logout") return;

    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = await this.props.authState.isAuthenticated;
    if (authenticated !== this.state.authenticated) {
      if (authenticated && !this.state.userinfo) {
        const userInfo = await this.props.oktaAuth.getUser();
        this.setState({ authenticated, userInfo });
      } else {
        this.setState({ authenticated });
      }
    }
  }

  async login() {
    let bValidCallbackUrl = this.checkCallbackUrl();
    if(!bValidCallbackUrl){
        console.log("Please provide a valid callback URL!");
	      window.location.href = "https://portal.haulerhero.com";
    } else {
        this.props.oktaAuth.signInWithRedirect({originalUri: '/next'});
    }
  }
  
  async alreadyLoggedIn() {
    this.checkCallbackUrl();
    this.next();
  }

  async next() {
    const accessToken = await this.props.authState.accessToken.accessToken;
   
    let cb = localStorage.getItem(Constants.hhAuthCallbackKey);
    let redirectUrl = `${cb}#access_token=${accessToken}`;
    console.log(redirectUrl);
    window.location.href = redirectUrl;
  }

  async logout() {
    var redirectUrl = this.checkPostLogoutRedirectUrl();
    if(redirectUrl){
      this.props.oktaAuth.signOut({ postLogoutRedirectUri: redirectUrl});
    }
  }

  async handlePath() {
    try {
      
      if(this.handlePathInProgess) {
        return;
      }

      this.handlePathInProgess = true;

      console.log("handlePath() START");
      console.log(this.state.authenticated);

      const accessToken = await this.props.authState.accessToken;
      console.log(accessToken);

      const userInfo = await this.props.oktaAuth.token.getUserInfo();
      console.log(userInfo.name);
      console.log("handlePath() END");
    }catch(e){
      this.login();
    }
  }

  checkPostLogoutRedirectUrl() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    if(params.postLogoutRedirectUrl){
      var redirectUrl = decodeURIComponent(params.postLogoutRedirectUrl.replace(/\+/g, ' '));
      console.log("PostLogoutRedirectUrl = " + redirectUrl);

      return redirectUrl;
    }
  }

  checkCallbackUrl() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    let bValidCallback = false;

    if(params.callback){
      var cb = decodeURIComponent(params.callback.replace(/\+/g, ' '));
      console.log("Callback = " + cb);
      for(var i in Constants.allowedCallbackUrls){
        if(Constants.allowedCallbackUrls[i] === params.callback){
          bValidCallback = true;
          break; 
        }
      }
    }

    if(bValidCallback){
      localStorage.setItem(Constants.hhAuthCallbackKey, cb);

      // check if there is a business id
      var bid = params.bid;
      if(bid) {
        localStorage.setItem(Constants.hhBusinessIdKey, bid);
      }
    } 

    return bValidCallback;
  }

  render() {

    var message = "";
    if(this.state.authenticated != null && !this.state.authenticated && this.props.mode === "login") {
	      message = "Logging in...";
        this.login();
    } else if(this.state.authenticated != null && this.state.authenticated && this.props.mode === "next") {
        message = "Navigating to app...";
        this.next();
    } else if(this.props.mode === "logout") {
	      message	= "Logging out..."
	      this.logout();
    } else if(this.state.authenticated != null && this.state.authenticated && this.props.mode === "login") {
        message = "Please wait...";
        this.alreadyLoggedIn();
    } else {
      this.handlePath();
    }

    return ( <div>{message}</div> );
  }

});
