import React, { Component } from 'react';
import Constants from './Constants';

class ForgotPasswordRequest extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let cb = localStorage.getItem(Constants.hhAuthCallbackKey);
    let redirectUrl = `${cb}/app/forgot-password`;
    window.location.href = redirectUrl;

    return ( <div>{Constants.successMessage}</div> );
  }
};

export default ForgotPasswordRequest;